import { makeStyles, createStyles } from '@material-ui/core/styles';

const RegistrationViewStyles = makeStyles((theme: any) =>
  createStyles({
    registrationWrapper: {
      display: "inline-block",
      position: "relative",
      float: "right",
      right: 0,
      width: 560,
      minHeight: 589,
      top: 75,
      height: "auto",
      backgroundColor: theme.palette.intakePaper.main,
      textAlign: "left",
      borderRadius: 10,
      marginBottom: 60,
      [theme.breakpoints.down("sm")]: {
        width: 400,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        top: 30,
      },
      "@media (max-width: 419.95px)": {
        width: 370,
      },
      "@media (max-width: 384.95px)": {
        width: 300,
      },
    },
    registerNowHeader: {
      fontSize: 24,
      position: "relative",
      top: 34,
      left: 50,
      marginBottom: 20,
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        left: 36,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 24,
        top: 35,
        left: 33,
        fontWeight: 400,
      },
      "@media (max-width: 419.95px)": {
        left: 25,
      },
      "@media (max-width: 384.95px)": {
        left: 15,
        top: 20,
      },
    },
    confirmationHolder: {
      display: "inline-block",
      position: "relative",
      right: -15,
      width: 560,
      height: 589,
      top: 75,
      backgroundColor: theme.palette.intakePaper.main,
      borderRadius: 10,
      [theme.breakpoints.down("sm")]: {
        width: 400,
      },
      [theme.breakpoints.down("xs")]: {
        top: 30,
        right: 0,
        width: "100%",
        height: 460,
      },
      "@media (max-width: 529.95px)": {},
      "@media (max-width: 419.95px)": {
        width: 370,
      },
      "@media (max-width: 384.95px)": {
        width: 300,
      },
    },
    deadLinkErrorHolder: {
      width: 560,
      position: "relative",
      display: "inline-block",
      right: 0,
      top: 75,
      minHeight: 80,
      backgroundColor: theme.palette.intakePaper.main,
      borderRadius: 10,
      [theme.breakpoints.down("xs")]: {
        width: 435,
        top: 50,
      },
    },
    deadLinkError: {
      fontSize: 24,
      display: "inline-block",
      wordWrap: "break-word",
      width: 460,
      position: "relative",
      top: 20,
      paddingBottom: 40,
      [theme.breakpoints.down("xs")]: {
        width: 360,
        fontSize: 20,
      },
    },
    deadLinkErrorHighlighted: {
      color: "#FFB700",
      display: "inline-block",
      wordWrap: "break-word",
      position: "relative",
      fontSize: 24,
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
      },
    },
    confirmationMessage: {
      fontSize: 24,
      position: "absolute",
      left: 45,
      top: 100,
      width: 420,
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        width: 340,
        top: 110,
      },
      [theme.breakpoints.down("xs")]: {
        width: 400,
        fontSize: 18,
        top: 75,
        left: 30,
      },
      "@media (max-width: 529.95px)": {
        fontSize: 17,
        width: 340,
      },
      "@media (max-width: 419.95px)": {
        fontSize: 16.5,
        width: 340,
      },
      "@media (max-width: 384.95px)": {
        width: 250,
      },
    },
    dateIcon: {
      marginRight: 15,
      position: "relative",
      top: 7,
    },
    eventLinkHeader: {
      fontSize: 16,
      fontFamily: "Open Sans, sans-serif",
      position: "absolute",
      top: 300,
      left: 45,
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
        top: 250,
        left: 30,
      },
      "@media (max-width: 529.95px)": {
        fontSize: 17,
      },
    },
    intakeLink: {
      color: "#00B6FF",
      fontSize: 16,
      fontFamily: "Open Sans, sans-serif",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      [theme.breakpoints.down("xs")]: {},
      "@media (max-width: 529.95px)": {},
      "@media (max-width: 384.95px)": {
        fontSize: 14,
        display: "inline-block",
        width: 200,
        wordWrap: "break-word",
      },
    },
    allSetHeader: {
      fontSize: 24,
      position: "absolute",
      top: 31,
      left: 45,
      [theme.breakpoints.down("xs")]: {
        fontSize: 22,
        top: 25,
        left: 30,
      },
      "@media (max-width: 529.95px)": {
        fontSize: 20,
      },
    },
    addToCalendarButton: {
      width: 220,
      top: 215,
      left: 45,
      height: 60,
      display: "inline-block",
      padding: 0,
      position: "absolute",
      backgroundColor: "#00B6FF",
      color: "white",
      [theme.breakpoints.down("xs")]: {
        width: "89%",
        left: 30,
        top: 155,
      },
      "@media (max-width: 529.95px)": {
        width: "85%",
      },
      "@media (max-width: 384.95px)": {
        width: "81%",
      },
    },
    emailMessage: {
      position: "absolute",
      fontSize: 16,
      top: 385,
      left: 45,
      textAlign: "left",
      display: "inline-block",
      width: 475,
      [theme.breakpoints.down("sm")]: {
        width: 320,
      },
      [theme.breakpoints.down("xs")]: {
        width: 400,
        left: 30,
        fontSize: 16,
        color: "#969696",
        top: 365,
      },
      "@media (max-width: 529.95px)": {
        width: 350,
      },
      "@media (max-width: 384.95px)": {
        fontSize: 15,
        width: 250,
      },
    },
    confirmDivider: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "inline-block",
        position: "absolute",
        width: "89%",
        left: 30,
        bottom: 125,
      },
      "@media (max-width: 529.95px)": {
        width: "85%",
      },
      "@media (max-width: 384.95px)": {
        width: "81%",
      },
    },
    addToCalendarButtonLabel: {
      position: "relative",
      bottom: 2
    }
  })
);

export { RegistrationViewStyles };