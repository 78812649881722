import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import TimezoneIcon from '@material-ui/icons/AccessTime';
import DateFnsUtils from '@date-io/date-fns';
import { orderBy } from 'natural-orderby';
import axios from 'axios';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import "../App.css";
import { ServerHelper } from '../utilities/ServerHelper';
import RegistrationView from '../RegistrationView/RegistrationView';

import styles from './CalendarForm.module.css';
import { CalendarFormStyles } from './CalendarFormStyles';

interface Props {
  
}

//Function for setting CSS rules
function setStyleRule (selector, rule, sheetName) {
  var sheets = document.styleSheets,
      stylesheet = sheets[(sheets.length - 1)];
  
  for( var i in document.styleSheets ){
      if( sheets[i].href && sheets[i].href.indexOf(sheetName + ".css") > -1 ) {
          stylesheet = sheets[i];
          break;
      }
  }
  
  if( stylesheet.addRule )
      stylesheet.addRule(selector, rule);

  else if( stylesheet.insertRule )
      stylesheet.insertRule(selector + ' { ' + rule + ' }', stylesheet.cssRules.length);
}

export default function CalendarForm(props: Props): ReactElement {

  const [ selectedDate, setSelectedDate ] = useState(null);

  const [ bookingSelectComplete, toggleBookingSelectComplete ] = useState(false);

  const [ dayBookings, setDayBookings ] = useState([] as any[]);

  const [ calendarBookings, setCalendarBookings ] = useState([] as any[]);

  const [ calendarMonthDateYearArray, setCalendarMonthDateYearArray ] = useState([""]);

  const [ currentMonthDate, setCurrentMonthDate ] = useState(new Date());

  const [ selectedBookingState, setSelectedBookingState ] = useState(null);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ registrationError, toggleRegistrationError ] = useState(false);

  const selectedDateRef = useRef(null);
  const selectedBooking = useRef(null);
  const firstDateChange = useRef(true);
  const bookingOnCurrentDate = useRef(false);

  const getDateBookings = (selectedDate) => {
    let dayBookings = [];

    for (let i = 0; i < calendarBookings.length; i++) {
      
      if (moment(calendarBookings[i].start.time).format("M D Y") === moment(selectedDate).format("M D Y")) {

        //double check that the end time is not after current time (event has ended)
        if (new Date(calendarBookings[i].end.time).getTime() < new Date().getTime()) {
          continue; 
        } else {
          dayBookings.push(calendarBookings[i]);
        }

      }
    };

    //Order day bookings
    const sortedDayBookings = orderBy(
      dayBookings,
      [b => new Date(b.start.time).getTime()], 
      ['asc']
    );

    if (!dayBookings.length) {
      setDayBookings([]);
      return [];
    } else {
      setDayBookings(sortedDayBookings);
      return sortedDayBookings;
    }
  }

  useEffect(() => {

    //Change header colors based on dark vs. light mode
    let headerElements = Array.from(document.getElementsByClassName("MuiPickersCalendarHeader-dayLabel"));

    headerElements.map(element => {
      if (ServerHelper.ApplicationSkin.theme === "light") {
        element.classList.add("headerLightMode");
      } else {
        element.classList.add("headerDarkMode");
      }
    });

    let stylesheet = document.styleSheets[5];

    if (ServerHelper.ApplicationSkin.theme === "light") {
      stylesheet.insertRule(".MuiPickersSlideTransition-transitionContainer p { color: black !important }")
    } else {
      stylesheet.insertRule(".MuiPickersSlideTransition-transitionContainer p.MuiTypography-body1 { color: white !important }")
    }

  }, []);

  useEffect(() => {
    
    console.log(ServerHelper.calendar);
    
    //Set state with bookings on calendar object

    let calendarBookingsArray = [];

    for (let item in (ServerHelper.calendar as any).bookings) {

      //convert booking times to user's timezone
      let userTimezone = moment.tz.guess();

      ServerHelper.calendar.bookings[item].start.timezone = userTimezone;
      ServerHelper.calendar.bookings[item].start.time = moment.tz(ServerHelper.calendar.bookings[item].start.time, userTimezone).format();
      ServerHelper.calendar.bookings[item].end.timezone = userTimezone;
      ServerHelper.calendar.bookings[item].end.time = moment.tz(ServerHelper.calendar.bookings[item].end.time, userTimezone).format();

      calendarBookingsArray.push(ServerHelper.calendar.bookings[item]);
    }

    setCalendarBookings(calendarBookingsArray);

    //Create array of month and date combinations for each booking
    let calendarBookingsMonthDateYearArray = [];
    
    for (let i = 0; i < calendarBookingsArray.length; i++) {
      calendarBookingsMonthDateYearArray.push(moment(calendarBookingsArray[i].start.time).format("M D Y"))
    };

    setCalendarMonthDateYearArray(calendarBookingsMonthDateYearArray);

  }, []);

  useEffect(() => {
    console.log("AAAA", calendarMonthDateYearArray);
  }, [calendarMonthDateYearArray]);

  //Get initial date bookings
  useEffect(() => {

    //Check if there is a booking on current date
    let currentDateBookings = getDateBookings(new Date());
    let currentDateBookingBoolean;
    
    if (currentDateBookings.length) {
      currentDateBookingBoolean = true;
      bookingOnCurrentDate.current = true;
    } else {
      currentDateBookingBoolean = false;
      bookingOnCurrentDate.current = false;
    }

    //If there is a booking on current date, select it and show blue background
    if (currentDateBookingBoolean) {
      setSelectedDate(new Date());
      setStyleRule("button.MuiPickersDay-daySelected", "background-color: #00B6FF !important", "App.css");
      setStyleRule("div#currentDay button span p", "color: white !important", "App.css");
    }
  }, [calendarBookings])

  const handleDateChange = (date: any) => {

    //If this is the first date change, apply blue background to selected button css class
    if (firstDateChange.current) {
      firstDateChange.current = false;
      setStyleRule("button.MuiPickersDay-daySelected", "background-color: #00B6FF !important", "App.css");
      setStyleRule("div#currentDay button span p", "color: #00B6FF !important", "App.css");
      setStyleRule("button.MuiPickersDay-daySelected:hover", "background-color: #00B6FF !important", "App.css");
    }

    //If the day clicked is currentDay
    if (moment(date).format("M D Y") === moment().format("M D Y")) {
      firstDateChange.current = true;
      setStyleRule("div#currentDay button span p", "color: white !important", "App.css");
    }

    //Clear selected booking
    selectedBooking.current = null;
    setSelectedBookingState(null);

    setSelectedDate(date);
    selectedDateRef.current = date;
  };

  const handleMonthChange = (date: any) => {
    setCurrentMonthDate(date);
  }

  //useEffect to show bookings in right sidebar
  useEffect(() => {

    getDateBookings(selectedDate);

  }, [selectedDate]);


  //Next button
  const handleNextButtonClick = () => {

    toggleLoadingSpinner(true);

    toggleRegistrationError(false);

    //Double check that booking is not null
    if (selectedBooking.current == null) {
      return;
    }

    //Check login code
    axios.get("/registration/login", {
      params: {
        loginCode : (selectedBooking! as any).current.signUpLoginCode,
        timestamp: new Date().getTime()
      }
    })
    .then(function(response) {
      toggleLoadingSpinner(false);

      //Set replyToEmailAddress in ServerHelper
      ServerHelper.replyToEmailAddress = response.data.replyToEmailAddress

      //Set intakeFields for registration form
      ServerHelper.ApplicationSkin.intakeFields = response.data.uiSkin.intakeFields;
      ServerHelper.ApplicationSkin.intakeFields[3].mandatory = true;
      ServerHelper.ApplicationSkin.intakeFields[3].visible = true;

      ServerHelper.bookingID = response.data.eventID;

      toggleBookingSelectComplete(true);
    })
    .catch(function(error) {
      console.log("ERROR", error);
      toggleRegistrationError(true);
      toggleLoadingSpinner(false);
    })
    
  }

  const handleChangeDateAndTimeClick = () => {

    setStyleRule("button.MuiPickersDay-daySelected", " background-color: #00B6FF !important", "App.css");
    setStyleRule("button.MuiPickersDay-daySelected:hover", "background-color: #00B6FF !important", "App.css");
    
    toggleBookingSelectComplete(false);
  }

  //Custom day renderer to customize each day in calendar
  const renderDay = (day : any, selected: any, dayInCurrentMonth: any, dayComponent: any) => {

    let currentMonth = moment().format("M");
    let currentDay = moment().format("D");
    let currentYear = moment().format("Y");

    //Check if we are in currentMonth, and if day is equal to the currentDay (to display current day border), and that year is equal to currentYear
    let currentDayBoolean;

    if ((moment(day).format("D") === currentDay) && (moment(day).format("M") === currentMonth) && (moment(day).format("Y") === currentYear)) {
      currentDayBoolean = true;
    } else {
      currentDayBoolean = false;
    }

    //loop through month and date array and see if it includes the current day month/day combination
    if (calendarMonthDateYearArray.includes(moment(day).format("M D Y"))) {

      let includeBoolean;

      //Make sure there is at least one calendarBooking with an end time that is after the current time
      for (let i = 0; i < calendarBookings.length; i++) {
        let booking = calendarBookings[i];

        if (moment(booking.start.time).format("M D Y") === moment(day).format("M D Y")) {

          //We found a match, now make sure end time is not before current time
          if (moment.utc(booking.end.time).isBefore(moment.utc())) {
            includeBoolean = false;
          } else {
            includeBoolean = true;
          }

        }
      }

      if (includeBoolean) {

        //If date is selected, change text color to white 
        if (moment(day).format("M D Y") === moment(selectedDateRef.current).format("M D Y")) {
          
          //Render white border if current day
          if (currentDayBoolean) {
            return (
              <div className={`${styles.bookingDayHolderSelected} ${ServerHelper.ApplicationSkin.theme === "light" ? styles.bookingDayHolderCurrentDayLight : styles.bookingDayHolderCurrentDayDark}`} id="currentDay">
                {dayComponent}
              </div>
            )
          } else {
            return (
              <div className={styles.bookingDayHolderSelected}>
                {dayComponent}
              </div>
            )
          }
          
        } else {

          if (currentDayBoolean) {
            return (
              <div className={`${styles.bookingDayHolder} ${ServerHelper.ApplicationSkin.theme === "light" ? styles.bookingDayHolderCurrentDayLight : styles.bookingDayHolderCurrentDayDark}`} id="currentDay">
                {dayComponent}
              </div>
            )
          } else {
            return (
              <div className={styles.bookingDayHolder}>
                {dayComponent}
              </div>
            )
          }
          
        }
      
      } else {
        if (ServerHelper.ApplicationSkin.theme === "light") {
          return (
            <div className={currentDayBoolean ? "dayLightMode dayLightModeCurrentDay" : "dayLightMode"}>
              {dayComponent}
            </div>
          )
        } else {
          return (
            <div className={currentDayBoolean ? "dayDarkMode dayDarkModeCurrentDay" : "dayDarkMode"}>
              {dayComponent}
            </div>
          )
          
        }
      }

    } else {

      //change day class based on theme

      if (ServerHelper.ApplicationSkin.theme === "light") {
        return (
          <div className={currentDayBoolean ? "dayLightMode dayLightModeCurrentDay" : "dayLightMode"}>
            {dayComponent}
          </div>
        )
      } else {
        return (
          <div className={currentDayBoolean ? "dayDarkMode dayDarkModeCurrentDay" : "dayDarkMode"}>
            {dayComponent}
          </div>
        )
        
      }
    }
  };

  //Booking select in right sidebar
  const handleBookingSelect = (booking: any) => {

    //If selectedBooking is already the clicked booking, deselect it
    if (selectedBooking.current !== null && ((selectedBooking.current! as any).bookingID === booking.bookingID)) {
      selectedBooking.current = null;
      setSelectedBookingState(null);
    } else {
      selectedBooking.current = booking;
      setSelectedBookingState(booking);
    }

  }
  
  const classes = CalendarFormStyles();

  if (bookingSelectComplete) {
    return (
      <RegistrationView handleChangeDateAndTimeClick={handleChangeDateAndTimeClick} selectedBooking={selectedBooking.current}/>
    )
  } else {
    return (
      <React.Fragment>
        <Paper className={classes.calendarFormHolder}>
          <Typography variant="h2" className={classes.calendarHeader}>
            Pick a Date & Time
          </Typography>
  
          <div className={styles.calendarHolder}>

            <Paper className={classes.calendarWrapper} elevation={0} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  defaultValue={null}
                  value={selectedDate}
                  className={classes.calendar}
                  onChange={handleDateChange}
                  renderDay={renderDay}
                  TextFieldComponent={() => null}
                  open={true}
                  variant="static"
                  onMonthChange={handleMonthChange}
                  leftArrowButtonProps={{
                    classes: {
                      root: classes.leftArrow
                    }
                  }}
                  rightArrowButtonProps={{
                    classes: {
                      root: classes.rightArrow
                    }
                  }}
                >
                </DatePicker>
              </MuiPickersUtilsProvider>
            </Paper>

          </div>
        </Paper>
  
        <Paper className={classes.bookingsHolder}>
          
          {calendarBookings[0] !== undefined &&
            <div className={styles.timezoneWrapper}>

              <TimezoneIcon className={classes.timezoneIcon} />
              
              <Typography variant="h2" className={classes.timezoneHeader}>
                {`${moment.tz(moment.tz.guess()).format("z")} - ${moment.tz.guess()}`}
              </Typography>
             
            </div>
          }
          
          {registrationError &&
          
            <Typography variant="body1" className={classes.error}>
               An error occurred finding this event. Please try again.
            </Typography>

          }

          <div className={styles.timesHolder}>
            {dayBookings.map(booking => {
              if (selectedBooking.current !== null && (booking.bookingID === (selectedBooking.current as any).bookingID)) {
                return (
                  <Button key={booking.bookingID} className={`${classes.bookingButton} ${classes.bookingButtonSelected}`} variant="outlined" onClick={() => handleBookingSelect(booking)}>
                    {moment(booking.start.time).format("h:mm a")}
                  </Button>
                )
              } else {
                return (
                  <Button key={booking.bookingID} className={classes.bookingButton} variant="outlined" onClick={() => handleBookingSelect(booking)}>
                    {moment(booking.start.time).format("h:mm a")}
                  </Button>
                )
              }
            })}
          </div>
  
          
  
          <Button 
            disabled={selectedBooking.current === null || loadingSpinner} 
            className={!dayBookings.length ? `${classes.nextButton} ${classes.nextButtonNoBookings}` : classes.nextButton } 
            onClick={handleNextButtonClick} 
            variant="contained"
          >
            NEXT

            {loadingSpinner &&
            <CircularProgress className={classes.loadingSpinner} />
            }
          </Button>
        </Paper>
      </React.Fragment>
    )
  }
  
  
}
